import 'jquery';
import { default as $ } from 'jquery';

$(document).on('DOMContentLoaded', function() {
  var windowTop = $(window).scrollTop();
  var windowBottom = windowTop + $(window).height();

  $(".m-nav-card.slideUp").each(function() {
    $(this).removeClass('slideUp');
  });

  $(".addAnimation").each(function() {
    var objectTop = $(this).offset().top;
    var objectBottom = objectTop + $(this).height();

    if (objectBottom > windowTop && objectTop < windowBottom) {
      $(this).addClass('m-fadeIn');
    }
  });

  $(".addSlide").each(function() {
    var objectTop = $(this).offset().top;
    var objectBottom = objectTop + $(this).height();

    if (objectBottom > windowTop && objectTop < windowBottom) {
      $(this).addClass("m-slide");
    }
  });

  $(".footer-form-image").each(function() {
    var objectTop = $(this).offset().top;
    var objectBottom = objectTop + $(this).height();

    if (objectBottom > windowTop && objectTop < windowBottom) {
      $(this).addClass("-scrollUp");
    }
  });

  $(".cardAnimation").each(function() {
    var objectTop = $(this).offset().top;
    var objectBottom = objectTop + $(this).height();

    if (objectBottom > windowTop && objectTop < windowBottom) {
      $(this).find('.-card').addClass('cardFade');
    }
  });

  $(".linkTabAnimation").each(function() {
    var objectTop = $(this).offset().top;
    var objectBottom = objectTop + $(this).height();

    if (objectBottom > windowTop && objectTop < windowBottom) {
      $(this).find('.button--tab').addClass('cardFade');
    }
  });

  $(".messageBoxAnimation").each(function() {
    var objectTop = $(this).offset().top;
    var objectBottom = objectTop + $(this).height();

    if (objectBottom > windowTop && objectTop < windowBottom) {
      $(this).find('.-card').addClass('cardFade').addClass('message-animate');
    }
  });

  $(".boxAnimation").each(function() {
    var objectTop = $(this).offset().top;
    var objectBottom = objectTop + $(this).height();

    if (objectBottom > windowTop && objectTop < windowBottom) {
      $(this).addClass('-animate');
    }
  });

  $(".boxAnimationAlt").each(function() {
    var objectTop = $(this).offset().top;
    var objectBottom = objectTop + $(this).height();

    if (objectBottom > windowTop && objectTop < windowBottom) {
      $(this).addClass('-animate');
    }
  });

  $(".triangle-container.triangle-animate").each(function() {
    var objectTop = $(this).offset().top;
    var objectBottom = objectTop + $(this).height();

    if (objectBottom > windowTop && objectTop < windowBottom) {
      $(this).removeClass('triangle-animate');
    }
  });

  $(".triangle-container.triangle-animate-alt").each(function() {
    var objectTop = $(this).offset().top;
    var objectBottom = objectTop + $(this).height();

    if (objectBottom > windowTop && objectTop < windowBottom) {
      $(this).removeClass('triangle-animate-alt').addClass('-slide');
    }
  });

  $(".animateBannerImage").each(function() {
    $(this).addClass("slideIn");
  });
});

$(window).on("load",function() {
  $(window).scroll(function() {
    var windowTop = $(window).scrollTop();
    var windowBottom = windowTop + $(window).height();

    $(".addAnimation").each(function() {
      var objectTop = $(this).offset().top;
      var objectBottom = objectTop + $(this).height();

      if (objectBottom > windowTop && objectTop < windowBottom) {
        $(this).addClass('m-fadeIn');
      }
    });

    $(".addSlide").each(function() {
      var objectTop = $(this).offset().top;
      var objectBottom = objectTop + $(this).height();
  
      if (objectBottom > windowTop && objectTop < windowBottom) {
        $(this).addClass("m-slide");
      }
    });

    $(".footer-form-image").each(function() {
      var objectTop = $(this).offset().top;
      var objectBottom = objectTop + $(this).height();

      if (objectBottom > windowTop && objectTop < windowBottom) {
        $(this).addClass("-scrollUp");
      }
    });

    $(".cardAnimation").each(function() {
      var objectTop = $(this).offset().top;
      var objectBottom = objectTop + $(this).height();

      if (objectBottom > windowTop && objectTop < windowBottom) {
        $(this).find('.-card').addClass('cardFade');
      }
    });

    $(".linkTabAnimation").each(function() {
      var objectTop = $(this).offset().top;
      var objectBottom = objectTop + $(this).height();
  
      if (objectBottom > windowTop && objectTop < windowBottom) {
        $(this).find('.button--tab').addClass('cardFade');
      }
    });

    $(".messageBoxAnimation").each(function() {
      var objectTop = $(this).offset().top;
      var objectBottom = objectTop + $(this).height();
  
      if (objectBottom > windowTop && objectTop < windowBottom) {
        $(this).find('.-card').addClass('cardFade').addClass('message-animate');
      }
    });
  
    $(".boxAnimation").each(function() {
      var objectTop = $(this).offset().top;
      var objectBottom = objectTop + $(this).height();
  
      if (objectBottom > windowTop && objectTop < windowBottom) {
        $(this).addClass('-animate');
      }
    });

    $(".boxAnimationAlt").each(function() {
      var objectTop = $(this).offset().top;
      var objectBottom = objectTop + $(this).height();
  
      if (objectBottom > windowTop && objectTop < windowBottom) {
        $(this).addClass('-animate');
      }
    });

    $(".triangle-container.triangle-animate").each(function() {
      var objectTop = $(this).offset().top;
      var objectBottom = objectTop + $(this).height();
  
      if (objectBottom > windowTop && objectTop < windowBottom) {
        $(this).removeClass('triangle-animate');
      }
    });
  
    $(".triangle-container.triangle-animate-alt").each(function() {
      var objectTop = $(this).offset().top;
      var objectBottom = objectTop + $(this).height();
  
      if (objectBottom > windowTop && objectTop < windowBottom) {
        $(this).removeClass('triangle-animate-alt').addClass('-slide');
      }
    });
  }).scroll();
});
