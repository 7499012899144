// This file contains all parachute imports, e.g:
import './accordion/accordion';
import './animation/animation';
import './mmenu/mmenu';
import './header/header';
import './carousel/carousel';
import './splitting/splitting';
import './carousel/posts';
import './banner/banner';
import './tabs/tabs';
import './carousel/links';
import './carousel/infinite';
import './carousel/team';
import './carousel/case-studies';
import './carousel/projects';
import './framework/framework';
import './levels/descriptors';
import './register/register';
import './nav/nav-bar';
import './rpl/rpl';
import './carousel/custom';

// Publicly accessible methods for this build, useful for callbacks in script tags etc.
// Try and keep most things inside this webpack bundle though
import { publicExports } from './public-exports';
window._Parachute = publicExports || {};
